import React, { useState, useContext } from 'react';
import ComponentBase from '../../../componentBase/ComponentBase';
import ProgressBar from './ProgressBar';
import SortableList from '../../../SortableList';
import { FieldsAndNumberValues } from '../../../../../../model/backendDataModels';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../../utils/BackendError';
import LoadingIndicator from '../../../../../common/LoadingIndicator';
import { AppContext } from '../../../../../../App';
import { Box, Pagination } from '@mui/material';
import { sortData } from '../../../../../../utils/dataSortUtils';
import { Moment } from 'moment-timezone';
const MAX_ITEMS_PER_PAGE = 10;

type DeviceCompletedListProps = {
  dataHook: (startDate: Moment, endDate: Moment) => SWRResponse<FieldsAndNumberValues, BackendError>;
  title: string;
  startDate: Moment;
  endDate: Moment;
  help?: React.ReactNode;
};

export default function DeviceCompletedList({ dataHook, title, startDate, endDate, help }: DeviceCompletedListProps): JSX.Element {
  const appContext = useContext(AppContext);

  const [sortCol, setSortCol] = useState(2);
  const [sortAsc, setSortAsc] = useState(false);
  const [page, setPage] = useState(1);

  const { data, error, isLoading, isValidating } = dataHook(startDate, endDate);

  if (error) {
    appContext.addBackendError(error);
  }

  const formattedData = data
    ? data.values.map((values: number[]) => {
        const percent = Math.round(((values[2] - values[1]) / values[2]) * 100);
        return [
          values[0],
          <>
            <ProgressBar percent={percent} color='info' />
          </>,
          percent,
        ];
      })
    : undefined;

  const sortedData = formattedData ? sortData<string | number | JSX.Element>(formattedData, sortCol, sortAsc) : undefined;

  const paginatedData = sortedData
    ? sortedData.slice((page - 1) * MAX_ITEMS_PER_PAGE, Math.min(sortedData.length, page * MAX_ITEMS_PER_PAGE))
    : undefined;
  const totalNumberOfPages = sortedData ? Math.ceil(sortedData.length / MAX_ITEMS_PER_PAGE) : 0;
  const headers = ['Device ID', 'Progress', '%'];

  return (
    <ComponentBase title={title} help={help} sx={{ display: 'flex', flexDirection: 'column' }}>
      {!(isLoading || isValidating) && data && paginatedData && paginatedData.length > 0 && !error && (
        <>
          <SortableList
            data={paginatedData}
            headers={headers}
            widths={[0.3, 0.6, 0.1]}
            changeDataParameters={(sortCol: number, sortAsc: boolean): void => {
              setSortCol(sortCol);
              setSortAsc(sortAsc);
            }}
            sortableColumns={[0, 2]}
          />

          {page > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '5px' }}>
              {totalNumberOfPages > 1 && (
                <Pagination
                  count={totalNumberOfPages}
                  page={page}
                  onChange={(event, page): void => {
                    setPage(page);
                  }}
                />
              )}
            </Box>
          )}
        </>
      )}
      <Box sx={{ alignContent: 'center', flexGrow: 1 }}>{(isLoading || isValidating) && <LoadingIndicator />}</Box>
    </ComponentBase>
  );
}
